import { Prospect } from "models/Prospect";
import { BaseServices } from "./baseServices";

export class ProspectService extends BaseServices<Prospect> {
	constructor() {
		super(Prospect);
	}

	async getDetails(id: any) {
		return super.get(`backoffice/partners/prospects/${id}`);
	}

	async getUserDetails(id: any) {
		return super.get(`user/${id}`);
	}

	async createProspect(data: any) {
		const prospectValidationUrl = `${window.location.protocol}//${window.location.host}/backoffice/partners/prospects`;
		return super.create("public/partners/prospects", {
			...data,
			prospectValidationUrl
		});
	}

	async rejectProspect(id: any) {
		return super.patch(`backoffice/partners/prospects/${id}/reject`);
	}

	async acceptProspect(id: any, partnerRegisterId: any) {
		const partnerRegistrationUrl = `${window.location.protocol}//${window.location.host}/parceiro/termo/${partnerRegisterId}`;
		return super.patch(`backoffice/partners/prospects/${id}/accept`, {
			partnerRegistrationUrl
		});
	}
}
