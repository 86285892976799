import { IconProps } from "./types";

export default function IcoCoupon({
	color = "#1D1D1D",
	size = "24",
	viewBox = "0 0 24 24"
}: IconProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox={viewBox}
			fill="none"
		>
			<path
				d="M8.5 9H13.5M8.5 12H16M8.5 15H13.5M4.02127 9.02127C4.00765 9.00765 4 8.98918 4 8.96992V7C4 6.44772 4.44772 6 5 6H19C19.5523 6 20 6.44772 20 7V17C20 17.5523 19.5523 18 19 18H5C4.44772 18 4 17.5523 4 17V15.0301C4 15.0108 4.00765 14.9923 4.02127 14.9787C5.66638 13.3336 5.66638 10.6664 4.02127 9.02127Z"
				stroke={color}
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
