import { Outlet } from "react-router-dom";

import { Header } from "components";
import Footer from "components/Footer";
import { useState } from "react";

export default function DefaultLayout() {
	const [redirectModalOpen, setRedirectModalOpen] = useState<boolean>(false);
	return (
		<>
			<Header setRedirectModalOpen={setRedirectModalOpen} />
			<Outlet context={{ redirectModalOpen }} />
			<Footer />
		</>
	);
}
