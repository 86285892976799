import colors from "colors";
import { IconProps } from "./types";

export default function IcoLogout({
	color = colors.neutral["low-pure-500"],
	size = "24",
	viewBox = "0 0 24 24"
}: IconProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox={viewBox}
			fill="none"
		>
			<path
				d="M10 12H20M20 12L15.5 16.5M20 12L15.5 7.5M10 20H4V4H10"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
