import { BaseModel } from "./BaseModel";

export enum ProspectFields {
	id = "id",
	responsibleName = "responsibleName",
	responsibleEmail = "responsibleEmail",
	responsiblePhone = "responsiblePhone",
	cnpj = "cnpj",
	tradeName = "tradeName",
	addressPostalCode = "addressPostalCode",
	addressStreet = "addressStreet",
	addressNumber = "addressNumber",
	addressComplement = "addressComplement",
	addressNeighbourhood = "addressNeighbourhood",
	addressCity = "addressCity",
	addressState = "addressState",
	categoryId = "categoryId",
	statusRegister = "statusRegister",
	prospectRegisterId = "prospectRegisterId",
	prospectValidationUrl = "prospectValidationUrl"
}

export interface ProspectInterface {
	[ProspectFields.id]?: string;
	[ProspectFields.responsibleName]: string;
	[ProspectFields.responsibleEmail]: string;
	[ProspectFields.responsiblePhone]: string;
	[ProspectFields.cnpj]: string;
	[ProspectFields.tradeName]: string;
	[ProspectFields.addressPostalCode]: string;
	[ProspectFields.addressStreet]: string;
	[ProspectFields.addressNumber]: string;
	[ProspectFields.addressComplement]: string;
	[ProspectFields.addressNeighbourhood]: string;
	[ProspectFields.addressCity]: string;
	[ProspectFields.addressState]: string;
	[ProspectFields.categoryId]: string;
	[ProspectFields.statusRegister]?: string;
	[ProspectFields.prospectRegisterId]?: string;
	[ProspectFields.prospectValidationUrl]?: string;
}

export class Prospect extends BaseModel implements ProspectInterface {
	[ProspectFields.id]!: string;
	[ProspectFields.responsibleName]!: string;
	[ProspectFields.responsibleEmail]!: string;
	[ProspectFields.responsiblePhone]!: string;
	[ProspectFields.cnpj]!: string;
	[ProspectFields.tradeName]!: string;
	[ProspectFields.addressPostalCode]!: string;
	[ProspectFields.addressStreet]!: string;
	[ProspectFields.addressNumber]!: string;
	[ProspectFields.addressComplement]!: string;
	[ProspectFields.addressNeighbourhood]!: string;
	[ProspectFields.addressCity]!: string;
	[ProspectFields.addressState]!: string;
	[ProspectFields.categoryId]!: string;
	[ProspectFields.statusRegister]!: string;
	[ProspectFields.prospectRegisterId]!: string;
	[ProspectFields.prospectValidationUrl]!: string;

	constructor(data: ProspectInterface) {
		super(data);

		Object.keys(ProspectFields).forEach((field) => {
			const temp = data[field as keyof typeof ProspectFields];
			if (temp) this[field as keyof typeof ProspectFields] = temp;
		});
	}

	static createFromResponse(response: any): Prospect {
		if (!response) {
			return response;
		}
		return new Prospect(response);
	}
}
