import colors from "colors";
import { IconProps } from "./types";

export default function IcoCopy({
	color = colors.neutral["low-pure-500"],
	size = "16",
	viewBox = "0 0 16 16"
}: IconProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox={viewBox}
			fill="none"
		>
			<path
				d="M3.625 11.75H3.5C2.67157 11.75 2 11.0784 2 10.25V3.5C2 2.67157 2.67157 2 3.5 2H10.25C11.0784 2 11.75 2.67157 11.75 3.5V3.625M7.25 14H12.5C13.3284 14 14 13.3284 14 12.5V7.25C14 6.42157 13.3284 5.75 12.5 5.75H7.25C6.42157 5.75 5.75 6.42157 5.75 7.25V12.5C5.75 13.3284 6.42157 14 7.25 14Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
