import {
	IcoArrowLeft,
	IcoArrowRight,
	IcoCloseModal,
	IcoCoupon,
	IcoLogout,
	IcoMenu
} from "assets/icons";
import MinimalistLogo from "assets/images/vainaboa-logo-minimalist";
import clsx from "clsx";
import colors from "colors";
import Profile from "components/Profile";
import AuthTokenHelper from "helpers/AuthTokenHelper";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { ProspectService } from "services/prospect";
import MenuItem from "./modules/MenuItem";

import "./styles.scss";
import { SidebarPartnerSectionProps } from "./types";

const sidebarData = [
	{
		title: "Validação",
		icon: (isSelected: boolean) => (
			<IcoCoupon
				color={
					isSelected
						? colors["charts-grape"]["700"]
						: colors.neutral["low-pure-500"]
				}
			/>
		),
		path: (partnerRegisterId: string) => `/area-parceiro/${partnerRegisterId}`
	}
];

function SidebarPartnerSection({
	partnerRegisterId
}: SidebarPartnerSectionProps) {
	const location = useLocation();
	const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
	const [isMobileSidemenuVisible, setIsMobileSidemenuVisible] = useState(false);

	const [isSidebarRetracted, setIsSidebarRetracted] = useState(false);

	const prospectService = new ProspectService();

	const navigate = useNavigate();

	const [partnerInfo, setPartnerInfo] = useState({
		tradeName: "",
		responsibleName: ""
	});

	const handleLogout = async () => {
		AuthTokenHelper.removeAuthToken();
		navigate("/");
	};

	useEffect(() => {
		prospectService.getUserDetails(partnerRegisterId).then((response) => {
			setPartnerInfo({
				tradeName: response.tradeName,
				responsibleName: response.responsibleName
			});
		});
	}, [partnerRegisterId]);

	return (
		<div
			className={clsx("sidebar-partner-section", {
				retracted: isSidebarRetracted,
				mobile: isMobile,
				"mobile-sidemenu-visible": isMobile && isMobileSidemenuVisible
			})}
		>
			{isMobile && (
				<div className="mobile-menu">
					<div
						className="hamburguer-icon"
						onClick={() => setIsMobileSidemenuVisible(true)}
					>
						<IcoMenu color={colors.neutral["low-pure-500"]} size="24" />
					</div>
					<p className="client-name">{partnerInfo.tradeName}</p>
				</div>
			)}
			<div className="content">
				<div className="header">
					<MinimalistLogo size="40" />
					{isMobile && (
						<div
							className="close-button"
							onClick={() => setIsMobileSidemenuVisible(false)}
						>
							<IcoCloseModal color={colors.neutral["low-pure-500"]} />
						</div>
					)}
				</div>
				<p className="client-name">{partnerInfo.tradeName}</p>
				<div className="menu-items">
					{sidebarData.map((item, index) => (
						<MenuItem
							key={index}
							title={item.title}
							icon={item.icon(
								location.pathname === item.path(partnerRegisterId)
							)}
							to={item.path(partnerRegisterId)}
							isSelected={location.pathname === item.path(partnerRegisterId)}
							isRetracted={isSidebarRetracted}
						/>
					))}
				</div>
				<div className="footer">
					<div className="profile">
						<Profile
							isRetracted={isSidebarRetracted}
							partnerResponsibleName={partnerInfo.responsibleName}
						/>
						{isMobile && (
							<div className="logout-button" onClick={handleLogout}>
								<IcoLogout />
							</div>
						)}
					</div>
					{!isMobile && !isSidebarRetracted && (
						<button
							className="retract-sidebar-button"
							onClick={() => setIsSidebarRetracted(true)}
						>
							<IcoArrowLeft size="24" color={colors.neutral["low-pure-500"]} />
							<p className="font-body-regular-small">Diminuir menu</p>
						</button>
					)}
					{!isMobile && isSidebarRetracted && (
						<button
							className="retract-sidebar-button"
							onClick={() => setIsSidebarRetracted(false)}
						>
							<IcoArrowRight size="24" color={colors.neutral["low-pure-500"]} />
						</button>
					)}
				</div>
			</div>
		</div>
	);
}

export default SidebarPartnerSection;
