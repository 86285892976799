import { IconProps } from "../icons/types";

export default function VaiNaBoaLogo({
	size = "40",
	viewBox = "0 0 40 40"
}: IconProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox={viewBox}
			fill="none"
		>
			<path
				d="M33.8358 32.6959L23.573 6.3797C22.9567 4.86982 21.8229 4 20.1662 4H19.8835C18.0854 4 17.045 4.86982 16.4314 6.3797L6.16582 32.6959C5.69368 33.9323 6.25912 35.4422 7.48896 35.8524C8.86016 36.3092 10.3755 35.6692 10.8477 34.4328L19.0608 10.2802C19.352 9.3639 20.6949 9.3639 20.9861 10.2802L29.1539 34.4328C29.6232 35.6692 31.1415 36.3092 32.5127 35.8524C33.7397 35.4394 34.3079 33.9295 33.833 32.6959H33.8358Z"
				fill="#9035F9"
			/>
		</svg>
	);
}
