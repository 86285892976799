import { baseConfig } from "config";
import { ErrorHelper } from "helpers";
import AuthTokenHelper from "helpers/AuthTokenHelper";
import { User } from "models/auth/User";
import { toast } from "react-toastify";
import { BaseServices } from "./baseServices";

export class ExternalAuthService extends BaseServices<User> {
	constructor() {
		super(User);
	}

	async loginB2C(partnerRegisterId?: string | undefined) {
		if (partnerRegisterId) {
			AuthTokenHelper.setCookie(partnerRegisterId, "partner_register_id");
		}
		AuthTokenHelper.removeAuthToken();

		const client_id = baseConfig.b2cClientId;
		const redirect = `${window.location.protocol}//${window.location.host}`;
		const b2c_url = baseConfig.b2cUrl;
		const loginURL = `${b2c_url}/oauth2/v2.0/authorize?p=B2C_1_SUSI&
			client_id=${client_id}&nonce=defaultNonce&scope=openid&
			response_type=id_token&prompt=login
			&redirect_uri=${redirect}/confirm-login`;

		window.location.assign(loginURL);
	}

	async logout() {
		return this.get("auth/logout/").catch((error) =>
			toast.error(ErrorHelper.getResponseErrorMessage(error))
		);
	}

	async verifyUser(partnerRegisterId: string | undefined) {
		return super.postWithResponse("user", {}, { partnerRegisterId });
	}
}
