import clsx from "clsx";
import { MenuItemProps } from "./types";

import "./styles.scss";

function MenuItem({
	title,
	icon,
	to,
	isSelected,
	isRetracted = false,
	...props
}: MenuItemProps) {
	return (
		<button
			className={clsx("menu-item", {
				selected: isSelected,
				retracted: isRetracted
			})}
			{...props}
		>
			{icon}
			{!isRetracted && <p>{title}</p>}
		</button>
	);
}

export default MenuItem;
