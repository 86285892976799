import { Outlet, useNavigate, useParams } from "react-router-dom";

import SidebarPartnerSection from "components/SidebarPartnerSection";
import usePartnerSectionToast from "hooks/usePartnerSectionToast";
import { useEffect } from "react";
import "./styles.scss";

export default function PartnerSectionLayout() {
	const { showErrorToast } = usePartnerSectionToast();
	const { partnerRegisterId } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (!partnerRegisterId) {
			showErrorToast("ID de parceiro não encontrado", "Erro");
			setTimeout(() => navigate("/"), 1000);
		}
	}, []);

	return (
		<div className="parner-section-layout">
			<SidebarPartnerSection partnerRegisterId={partnerRegisterId!} />
			<Outlet />
		</div>
	);
}
