import { IcoCopy } from "assets/icons";
import clsx from "clsx";
import Label from "components/Label";
import usePartnerSectionToast from "hooks/usePartnerSectionToast";
import { InputHTMLAttributes, useRef } from "react";
import "./styles.scss";

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
	label?: string;
	error?: string | Array<string>;
	className?: string;
	touched?: boolean;
	isOptional?: boolean;
	copyIcon?: boolean;
};

export default function Input({
	label,
	placeholder,
	type,
	name,
	value,
	disabled,
	error,
	touched,
	onChange,
	className = "",
	isOptional,
	copyIcon,
	...args
}: InputProps) {
	const { showSuccessToast } = usePartnerSectionToast();
	const inputRef = useRef<HTMLInputElement>(null);

	const handleCopy = () => {
		if (inputRef.current) {
			navigator.clipboard.writeText(inputRef.current.value);
		}

		showSuccessToast("Link copiado com sucesso!", "Link copiado");
	};

	return (
		<div
			className={clsx({ "disabled-text": disabled }, `container ${className}`)}
		>
			<div className="flex gap-1">
				{label && <Label isRequired={false}>{label}</Label>}
				{isOptional && <p className="text-sm">(opcional)</p>}
			</div>
			<div className="relative" {...(copyIcon ? { onClick: handleCopy } : {})}>
				<input
					ref={inputRef}
					type={type}
					name={name}
					value={value}
					placeholder={placeholder}
					onChange={onChange}
					disabled={disabled}
					className={clsx(
						{ error: error && touched, "disabled-text": disabled },
						`input-component ${className}`
					)}
					required={!isOptional}
					{...args}
				/>
				{copyIcon && (
					<div className="copy-button">
						<IcoCopy size="16" />
					</div>
				)}
			</div>
			{error && touched && (
				<div className="error-text">
					{Array.isArray(error) ? error.join("\n") : error}
				</div>
			)}
		</div>
	);
}
