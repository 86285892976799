import colors from "colors";
import { ComplexIconProps } from "./types";

export default function IcoErrorFilled({
	contentColor = colors.neutral["low-pure-500"],
	fillColor = "#E4204E",
	viewBox = "0 0 24 24",
	size = "24"
}: Readonly<ComplexIconProps>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox={viewBox}
			fill="none"
		>
			<path
				d="M4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12Z"
				fill={fillColor}
				stroke={fillColor}
				strokeLinecap="round"
			/>
			<path
				d="M15 9L9.1701 14.8299M14.8359 15L9.00603 9.1701"
				stroke={contentColor}
				strokeLinecap="round"
			/>
		</svg>
	);
}
