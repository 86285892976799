import clsx from "clsx";
import Label from "components/Label";
import React, { useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import "./styles.scss";

export const RE_DIGIT = /^[a-zA-Z0-9]+$/;

export type Props = {
	value: string;
	inputLength: number;
	onChange: (value: string) => void;
	label?: string;
	isOptional?: boolean;
	error?: string;
};

export default function OTPInput({
	value,
	inputLength,
	onChange,
	label,
	isOptional,
	error
}: Props) {
	const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

	const valueItems = useMemo(() => {
		const valueArray = value.split("");
		return Array.from({ length: inputLength }, (_, i) =>
			RE_DIGIT.test(valueArray[i]) ? valueArray[i] : ""
		);
	}, [value, inputLength]);

	const focusToNextInput = (target: HTMLElement) => {
		const nextElementSibling =
			target.nextElementSibling as HTMLInputElement | null;

		if (nextElementSibling) {
			nextElementSibling.focus();
		}
	};

	const focusToPrevInput = (target: HTMLElement) => {
		const previousElementSibling =
			target.previousElementSibling as HTMLInputElement | null;

		if (previousElementSibling) {
			previousElementSibling.focus();
		}
	};

	const inputOnChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		idx: number
	) => {
		const { target } = e;
		let targetValue = target.value.trim().toUpperCase();
		const isTargetValueDigit = RE_DIGIT.test(targetValue);

		if (!isTargetValueDigit && targetValue !== "") {
			return;
		}

		const nextInputEl = target.nextElementSibling as HTMLInputElement | null;

		if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== "") {
			return;
		}

		targetValue = isTargetValueDigit ? targetValue : " ";

		const targetValueLength = targetValue.length;

		if (targetValueLength === 1) {
			const newValue =
				value.substring(0, idx) + targetValue + value.substring(idx + 1);

			onChange(newValue);

			if (!isTargetValueDigit) {
				return;
			}

			focusToNextInput(target);
		} else if (targetValueLength === inputLength) {
			onChange(targetValue);

			target.blur();
		}
	};

	const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const { key } = e;
		const target = e.target as HTMLInputElement;

		if (key === "ArrowRight" || key === "ArrowDown") {
			e.preventDefault();
			focusToNextInput(target);
			return;
		}

		if (key === "ArrowLeft" || key === "ArrowUp") {
			e.preventDefault();
			focusToPrevInput(target);
			return;
		}

		const targetValue = target.value;

		target.setSelectionRange(0, targetValue.length);

		if (e.key === "Backspace" && targetValue === "") {
			focusToPrevInput(target);
		}
	};

	const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		const { target } = e;

		const prevInputEl =
			target.previousElementSibling as HTMLInputElement | null;

		if (prevInputEl && prevInputEl.value === "") {
			prevInputEl.focus();
			return;
		}

		target.setSelectionRange(0, target.value.length);
	};

	const inputOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
		e.preventDefault();
		const pasteData = e.clipboardData.getData("text").toUpperCase();
		if (RE_DIGIT.test(pasteData)) {
			const newValue = pasteData.slice(0, inputLength);
			onChange(newValue);

			const inputs = e.currentTarget.parentElement?.querySelectorAll("input");
			if (inputs) {
				const lastInput =
					inputs[Math.min(newValue.trim().length, inputLength) - 1];
				if (lastInput) {
					setTimeout(() => {
						focusToNextInput(lastInput);
					}, 1);
				}
			}
		}
	};

	return (
		<div className="flex flex-col gap-1">
			<div className="flex gap-1">
				{label && <Label isRequired={false}>{label}</Label>}
				{isOptional && <p className="text-sm">(opcional)</p>}
			</div>
			<div className="otp-group">
				{valueItems.map((digit, idx) => (
					<input
						key={idx}
						type="text"
						inputMode="numeric"
						autoComplete="one-time-code"
						pattern="\d{1}"
						maxLength={1}
						className={clsx("otp-input", {
							error,
							mobile: isMobile
						})}
						value={digit}
						onChange={(e) => inputOnChange(e, idx)}
						onKeyDown={inputOnKeyDown}
						onFocus={inputOnFocus}
						onPaste={inputOnPaste}
					/>
				))}
			</div>
			{error && <p className="text-sm">{error}</p>}
		</div>
	);
}
